@import '~antd/dist/antd.css';

:root {
  --white: #ffffff;

  --blue-100: #f0f2ff;
  --blue-200: #f2f4ff;
  --blue-400: #5768ff;
  --blue-600: #222b82;
  --blue: #000a66;

  --orange: #f89c3a;

  --red: #d81e5b;
  --red-200: #fff1f6;

  --green-200: #f1faf3;
  --green: #5ab56e;

  --gray-100: #efefef;
  --gray-200: #f7f7f7;
  --gray-300: #d6d6d6;
  --gray-400: #a4a4a4;
  --gray-500: #bbbbbb;
  --gray-700: #434343;
  --gray-800: #252525;
  --gray-900: #101010;

  --black: #000000;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

button,
a {
  cursor: pointer;
}

p {
  margin: 0;
}

html,
body,
input,
textarea,
section,
button {
  font-family: 'Work Sans', sans-serif;
  background-color: #efefef;
}

form {
  box-shadow: none !important;
}

@media (max-width: 1080px) {
  html {
    font-size: 93.76%;
  }
}

@media (max-width: 720px) {
  html {
    font-size: 87.5%;
  }
}
